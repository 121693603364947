#wrapper {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.maintenance-full-screen {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  box-sizing: border-box;
}

.text-h1 {
  font-size: 1.5em;
  font-weight: 700;
}

.text-h3 {
  font-size: 1.125em;
  font-weight: 700;
}

.maintenance-text-center {
  text-align: center;
}

.maintenance-text-large {
  font-size: 1.125em;
  font-weight: 700;
}

.maintenance-is-danger {
  color: #e53e3e;
}
