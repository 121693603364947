/* The CSS code `html { scroll-behavior: smooth; }` is setting the scroll behavior for the HTML element
to smooth. This means that when a user clicks on a link that navigates to a different section of the
page, the scrolling will be animated and smooth rather than instant. */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

swiper-container::part(button-prev) {
  height: 40px;
  width: 40px;
  background: #00000090;
  padding: 8px;
  border-radius: 50%;
  color: #fff;
}

swiper-container::part(button-next) {
  height: 40px;
  width: 40px;
  background: #00000090;
  padding: 8px;
  border-radius: 50%;
  color: #fff;
}

.recharts-bar-rectangle:hover {
  stroke: #1976d2;
  stroke-width: 1;
  fill-opacity: 0.9;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
